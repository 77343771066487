import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import AddIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const fetchBusinessUnitsData = async () => {
    try {
      const response = await apiService.get('/api/v1/businessUnits/list');
      if (response.status === 200) {
        const businessUnits = response.data.businessUnits;

        setColumns([
          { Header: 'Name', accessor: 'name', width: '35%', align: 'left' },
          { Header: 'Company', accessor: 'company', align: 'left' },
          { Header: 'Action', accessor: 'action', align: 'center' },
        ]);

        const formattedRows = businessUnits.map((unit) => ({
          id: unit._id,
          name: (
            <MDTypography display="block" variant="button" fontWeight="medium">
              {unit.name}
            </MDTypography>
          ),
          company: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.company ? unit.company.name : 'N/A'}
            </MDTypography>
          ),
          action: (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <Link to={`/business/edit/${unit._id}`}>
                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDelete(unit._id)}
              />
            </MDBox>
          ),
        }));

        setRows(formattedRows);
      }
    } catch (error) {
      toast.error('Failed to fetch business units data');
    }
  };

  useEffect(() => {
    fetchBusinessUnitsData();
  }, []);

  const handleDelete = async (businessUnitId) => {
    try {
      const response = await apiService.delete(`/api/v1/businessUnits/delete/${businessUnitId}`);
      if (response.status === 200) {
        toast.success('BusinessUnit deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== businessUnitId));
      }
    } catch (error) {
      toast.error('Failed to delete business Unit');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Business Units
                </MDTypography>
                <Link to={'/business/create'}>
                <Button
                    variant="contained"
                    style={{ color: '#1A73E8', background: 'white' }}
                    startIcon={<AddIcon style={{ color: '#1A73E8' }} />}
                  >
                    Add New Business Unit
                  </Button>
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
