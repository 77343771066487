import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import Avatar from "@mui/material/Avatar";
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';

function UserDetails() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  console.log(user);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await apiService.get(`/api/v1/users/detail/${id}`);
        if (response.status === 200) {
          setUser(response.data.user);
        }
      } catch (error) {
        toast.error('Failed to fetch user details');
      }
    };
    fetchUserDetails();
  }, [id]);

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await apiService.put(`/api/v1/users/update/${id}`, {
        declinedStatus: newStatus,
      });
      if (response.status === 200) {
        setUser({ ...user, declinedStatus: newStatus });
        toast.success(`User ${newStatus === 'approved' ? 'Approved' : 'Declined'} successfully`);
      }
    } catch (error) {
      toast.error('Failed to update user status');
    }
  };

  if (!user) return null;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  User Profile
                </MDTypography>
              </MDBox>
              <MDBox pt={5} pb={3} px={5}>
                <Grid container spacing={4}>
                  {/* Profile Image */}
                  <Grid item xs={12} md={4} lg={2} justifyContent="center">
                    <MDBox sx={{}}>
                      <MDTypography
                        style={{
                          marginBottom: '15px',
                        }}
                        variant="h6"
                        color="textPrimary"
                      >
                        Profile Image
                      </MDTypography>
                      <MDAvatar
                        src={`https://babtainnotify.com:81/${user.profileImage}`}
                        alt={user.fullName}
                        sx={{ width: 150, height: 150, boxShadow: 3 }}
                      />
                    </MDBox>
                    <MDBox
                      sx={{
                        marginTop: '20px',
                      }}
                    >
                      <MDTypography
                        style={{
                          marginBottom: '15px',
                        }}
                        variant="h6"
                        color="textPrimary"
                      >
                        Captured Image
                      </MDTypography>
                      <MDAvatar
                        src={`https://babtainnotify.com:81/${user.capturedImage}`}
                        alt={user.fullName}
                        sx={{ width: 150, height: 150, boxShadow: 3 }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={8} lg={10}>
                    <Card>
                      <ProfileInfoCard
                        title="Profile Information"
                        info={{
                          fullName: user?.fullName || 'N/A',
                          email: user?.email || 'N/A',
                          personNumber: user?.personNumber || 'N/A',
                          createdAt: new Date(user?.createdAt).toLocaleDateString(),
                          department: user?.department?.name || 'N/A',
                          departmentLocation: user?.department?.address || 'N/A',
                          businessUnit: user?.businessUnit || 'N/A',
                          role: user?.roles?.map((role) => role.name).join(', ') || 'N/A',
                        }}
                        shadow={false}
                      />
                    </Card>
                    {user.declinedStatus !== 'approved' && user.declinedStatus !== 'declined' && (
                      <MDBox mt={3} display="flex" justifyContent="flex-start">
                        <MDButton
                          variant="contained"
                          color="info"
                          onClick={() => handleStatusChange('approved')}
                        >
                          Approve
                        </MDButton>
                        <MDButton
                          variant="contained"
                          color="error"
                          onClick={() => handleStatusChange('declined')}
                          sx={{ ml: 2 }}
                        >
                          Decline
                        </MDButton>
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserDetails;
