import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import apiService from "../../services/ApiService"; // Import your API service
import { toast } from "react-hot-toast"; // Import toast for notifications
import { useNavigate } from "react-router-dom";

function Create() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    // address: "",
    // country: "",
    // city: "",
    // state: "",
    // taxReportingNumber: "",
    // legalReportingNumber: "",
    // legalEntityNumber: "",
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post("/api/v1/companies/create", formData);
      if (response.status === 200) {
        navigate("/companies");
        toast.success(response.data.message);
        setFormData({
          name: "",
          // address: "",
          // country: "",
          // city: "",
          // state: "",
          // taxReportingNumber: "",
          // legalReportingNumber: "",
          // legalEntityNumber: "",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to create company");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Create Company
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Company Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Address"
                    name="address"
                    variant="standard"
                    fullWidth
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Country"
                    name="country"
                    variant="standard"
                    fullWidth
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="City"
                    name="city"
                    variant="standard"
                    fullWidth
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="State"
                    name="state"
                    variant="standard"
                    fullWidth
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Tax Reporting Number"
                    name="taxReportingNumber"
                    variant="standard"
                    fullWidth
                    value={formData.taxReportingNumber}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Legal Reporting Number"
                    name="legalReportingNumber"
                    variant="standard"
                    fullWidth
                    value={formData.legalReportingNumber}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Legal Entity Number"
                    name="legalEntityNumber"
                    variant="standard"
                    fullWidth
                    value={formData.legalEntityNumber}
                    onChange={handleInputChange}
                  />
                </MDBox> */}
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Create;
