import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import { AuthContext } from "context/AuthContext";

function DeclinedUsers() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchDeclinedUsers();
  }, []);

  const fetchDeclinedUsers = async () => {
    try {
      const response = await apiService.get("/api/v1/users/declinedUsersList");

      if (response.status === 200) {
        const declinedUsers = response.data.users;

        setColumns([
          { Header: "Full Name", accessor: "fullName", align: "left" },
          { Header: "Email", accessor: "email", align: "left" },
          { Header: "Personal Number", accessor: "personalNumber", align: "left" },
          { Header: "Department", accessor: "department", align: "left" },
          { Header: "Role", accessor: "role", align: "left" },
          { Header: "Approval Request", accessor: "approvalRequest", align: "center" },
        ]);

        const formattedRows = declinedUsers.map((user) => ({
          id: user._id,
          fullName: (
            <MDTypography display="block" variant="button" fontWeight="medium">
              {user.fullName}
            </MDTypography>
          ),
          email: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.email || "N/A"}
            </MDTypography>
          ),
          personalNumber: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.personNumber || "N/A"}
            </MDTypography>
          ),
          department: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.department?.name || "N/A"}
            </MDTypography>
          ),
          role: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.roles.map((el) => el?.name).join(", ") || "N/A"}
            </MDTypography>
          ),
          approvalRequest: (
            <MDButton variant="outlined" color="success" onClick={() => handleApplyClick(user._id)}>
              Apply
            </MDButton>
          ),
        }));

        setRows(formattedRows);
      }
    } catch (error) {
      toast.error("Failed to fetch declined users");
    }
  };

  const handleApplyClick = async (userId) => {
    try {
      const response = await apiService.put(`/api/v1/users/update/${userId}`, {
        declinedStatus: "notMarked",
      });

      if (response.status === 200) {
        toast.success("Request sent successfully");
        fetchDeclinedUsers();
      }
    } catch (error) {
      toast.error("Failed to update user status");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Declined Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                  />
                ) : (
                  <MDBox display="flex" justifyContent="center" py={5}>
                    <MDTypography variant="h6" color="textSecondary">
                      No Declined Users Yet
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DeclinedUsers;
