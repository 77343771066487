import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService'; // Update the path according to your project structure
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

function CompanyDetail() {
  const { id } = useParams(); // Get the company ID from the URL params
  const [formData, setFormData] = useState({
    name: '',
  });

  // Fetch company data on component mount
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await apiService.get(`/api/v1/companies/detail/${id}`);
        const company = response.data.company; // Assuming the response structure
        setFormData({
          name: company.name || '',
        });
      } catch (error) {
        toast.error('Failed to fetch company data');
      }
    };

    fetchCompany();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Company Details
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form">
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Company Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    disabled
                    value={formData.name}
                  />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CompanyDetail;
