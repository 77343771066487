import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchCompaniesData = async () => {
      try {
        const response = await apiService.get('/api/v1/companies/list');
        if (response.status === 200) {
          const companies = response.data.companies;

          setColumns([
            { Header: 'Company Name *', accessor: 'name', width: '80%', align: 'left' },
            { Header: 'Action', accessor: 'action', align: 'center' },
          ]);

          const formattedRows = companies.map((company) => ({
            id: company._id,
            name: (
              <Link to={`/companies/view/${company._id}`}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {company.name}
                </MDTypography>
              </Link>
            ),
            action: (
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <Link to={`/companies/edit/${company._id}`}>
                  <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
                  {/* </MDTypography> */}
                </Link>
                <MDBox mx={1} />
                <DeleteOutline
                  fontSize="small"
                  color="error"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(company._id)}
                />
                {/* <MDTypography
                component="a"
                variant="caption"
                color="error"
                fontWeight="medium"
                onClick={() => handleDelete(department._id)}
                sx={{ mx: 1, cursor: "pointer" }}
              >
                Delete
              </MDTypography> */}
              </MDBox>

              // <MDBox display="flex" alignItems="center" justifyContent="center">
              //   <Link to={`/companies/edit/${company._id}`}>
              //     <MDTypography
              //       component="a"
              //       variant="caption"
              //       color="info"
              //       fontWeight="medium"
              //       sx={{ mx: 1 }}
              //     >
              //       Edit
              //     </MDTypography>
              //   </Link>
              //   <MDTypography
              //     component="a"
              //     variant="caption"
              //     color="error"
              //     fontWeight="medium"
              //     onClick={() => handleDelete(company._id)}
              //     sx={{ mx: 1, cursor: "pointer" }}
              //   >
              //     Delete
              //   </MDTypography>
              // </MDBox>
            ),
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error('Failed to fetch companies data');
      }
    };

    fetchCompaniesData();
  }, []);

  const handleDelete = async (companyId) => {
    try {
      const response = await apiService.delete(`/api/v1/companies/delete/${companyId}`);
      if (response.status === 200) {
        toast.success('Company deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== companyId));
      }
    } catch (error) {
      toast.error('Failed to delete company');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Companies
                </MDTypography>
                <Link to={'/companies/create'}>
                <Button
                    variant="contained"
                    style={{ color: '#1A73E8', background: 'white' }}
                    startIcon={<AddIcon style={{ color: '#1A73E8' }} />}
                  >
                    Add New Company
                  </Button>
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
