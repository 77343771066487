// import React, { useState, useEffect } from "react";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";
// import AddIcon from "@mui/icons-material/AddBox";
// import IconButton from "@mui/material/IconButton";
// import apiService from "../../services/ApiService";
// import { toast } from "react-hot-toast";
// import { Link } from "react-router-dom";

// function Tables() {
//   const [columns, setColumns] = useState([]);
//   const [rows, setRows] = useState([]);

//   useEffect(() => {
//     const fetchDepartmentsData = async () => {
//       try {
//         const response = await apiService.get("/api/v1/departments/list");
//         if (response.status === 200) {
//           const departments = response.data.departments;
//           setColumns([
//             { Header: "Name", accessor: "name", width: "30%", align: "left" },
//             { Header: "Business Units", accessor: "businessUnits", align: "left" },
//             { Header: "Action", accessor: "action", align: "center" },
//           ]);

//           const formattedRows = departments.map((department) => ({
//             id: department._id,
//             name: (
//               <MDTypography display="block" variant="button" fontWeight="medium">
//                 {department.name}
//               </MDTypography>
//             ),
//             businessUnits: (
//               <MDTypography variant="caption" color="text" fontWeight="medium">
//                 {department.businessUnits
//                   .map((b) => `${b.businessUnit?.name} (${b.status ? "Active" : "Inactive"})`)
//                   .join(", ")}
//               </MDTypography>
//             ),
//             action: (
//               <MDBox display="flex" alignItems="center" justifyContent="center">
//                 <Link to={`/departments/edit/${department._id}`}>
//                   <MDTypography component="a" variant="caption" color="info" fontWeight="medium">
//                     Edit
//                   </MDTypography>
//                 </Link>
//                 <MDBox mx={1} />
//                 <MDTypography
//                   component="a"
//                   variant="caption"
//                   color="error"
//                   fontWeight="medium"
//                   onClick={() => handleDelete(department._id)}
//                   sx={{ mx: 1, cursor: "pointer" }}
//                 >
//                   Delete
//                 </MDTypography>
//               </MDBox>
//             ),
//           }));

//           setRows(formattedRows);
//         }
//       } catch (error) {
//         toast.error("Failed to fetch departments data");
//       }
//     };

//     fetchDepartmentsData();
//   }, []);

//   const handleDelete = async (departmentId) => {
//     try {
//       const response = await apiService.delete(`/api/v1/departments/delete/${departmentId}`);
//       if (response.status === 200) {
//         toast.success("Department deleted successfully");
//         setRows((prevRows) => prevRows.filter((row) => row.id !== departmentId));
//       }
//     } catch (error) {
//       toast.error("Failed to delete department");
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={6} pb={3}>
//         <Grid container spacing={6}>
//           <Grid item xs={12}>
//             <Card>
//               <MDBox
//                 mx={2}
//                 mt={-3}
//                 py={3}
//                 px={2}
//                 variant="gradient"
//                 bgColor="info"
//                 borderRadius="lg"
//                 coloredShadow="info"
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="space-between"
//               >
//                 <MDTypography variant="h6" color="white">
//                   Departments
//                 </MDTypography>
//                 <Link to={"/departments/create"}>
//                   <IconButton color="white">
//                     <AddIcon />
//                   </IconButton>
//                 </Link>
//               </MDBox>
//               <MDBox pt={3}>
//                 <DataTable
//                   table={{ columns, rows }}
//                   isSorted={false}
//                   entriesPerPage={false}
//                   showTotalEntries={false}
//                   noEndBorder
//                 />
//               </MDBox>
//             </Card>
//           </Grid>
//         </Grid>
//       </MDBox>
//     </DashboardLayout>
//   );
// }

// export default Tables;

import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import AddIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Edit, DeleteOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import FormDialog from 'components/FormDialog';
import InformationDialog from 'components/InformationDialog';

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const fetchDepartmentsData = async () => {
    try {
      const response = await apiService.get('/api/v1/departments/list');
      console.log('response', response);
      if (response.status === 200) {
        const departments = response.data.departments;
        setColumns([
          { Header: 'Name', accessor: 'name', align: 'left' },
          { Header: 'Business Units', accessor: 'businessUnits', align: 'left' },
          { Header: 'Get Location', accessor: 'location', align: 'left' },
          { Header: 'Location Status', accessor: 'location_status', align: 'center' },
          { Header: 'Information', accessor: 'information', align: 'center' },
          { Header: 'Action', accessor: 'action', align: 'center' },
        ]);

        const formattedRows = departments.map((department) => ({
          id: department._id,
          name: (
            <MDTypography display="block" variant="button" fontWeight="medium">
              {department.name}
            </MDTypography>
          ),
          businessUnits: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {department.businessUnits
                .map((b) => `${b.businessUnit?.name} (${b.status ? 'Active' : 'Inactive'})`)
                .join(', ')}
            </MDTypography>
          ),
          location: <FormDialog  fetchDepartmentsData={fetchDepartmentsData}  departmentId={department._id} status={department.location_status} />,

          location_status: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {/* {department.status ? 'Active' : 'Inactive'} */}
              {department.location_status}
            </MDTypography>
          ),
          information: (
            <InformationDialog
              emailLog={department?.emailLog}
            />
          ),

          action: (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <Link to={`/departments/edit/${department._id}`}>
                {/* <MDTypography component="a" variant="caption" color="info" fontWeight="medium"> */}

                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
                {/* </MDTypography> */}
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDelete(department._id)}
              />
              {/* <MDTypography
                component="a"
                variant="caption"
                color="error"
                fontWeight="medium"
                onClick={() => handleDelete(department._id)}
                sx={{ mx: 1, cursor: "pointer" }}
              >
                Delete
              </MDTypography> */}
            </MDBox>
          ),
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows); // Initialize filtered rows
      }
    } catch (error) {
      toast.error('Failed to fetch departments data');
    }
  };
  useEffect(() => {
   

    fetchDepartmentsData();
  }, []);

  const handleDelete = async (departmentId) => {
    try {
      const response = await apiService.delete(`/api/v1/departments/delete/${departmentId}`);
      if (response.status === 200) {
        toast.success('Department deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== departmentId));
        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== departmentId)); // Update filtered rows
      }
    } catch (error) {
      toast.error('Failed to delete department');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter rows based on search query
    const newFilteredRows = rows.filter((row) => row.name.props.children.includes(query));
    setFilteredRows(newFilteredRows);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Departments
                </MDTypography>
                <Link to={'/departments/create'}>
                  <Button
                    variant="contained"
                    style={{ color: '#1A73E8', background: 'white' }}
                    startIcon={<AddIcon style={{ color: '#1A73E8' }} />}
                  >
                    Add New Department
                  </Button>
                </Link>
              </MDBox>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearch}
                  size="small"
                />
              </MDBox>
              <MDBox pt={3}>
                {filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No records found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
