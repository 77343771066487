import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import apiService from "../../services/ApiService"; // Update the path according to your project structure
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

function EditRole() {
  const { id } = useParams(); // Get the role ID from the URL params
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });

  // Fetch role data on component mount
  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await apiService.get(`/api/v1/roles/detail/${id}`);
        const role = response.data.role; // Assuming the response structure
        setFormData({
          name: role.name || "",
        });
      } catch (error) {
        toast.error("Failed to fetch role data");
      }
    };

    fetchRole();
  }, [id]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.put(`/api/v1/roles/update/${id}`, formData);
      if (response.status === 200) {
        navigate("/roles");
        toast.success("Role updated successfully");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update role");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Edit Role
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Role Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Save Changes
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditRole;
