import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from 'services/ApiService';
import { AuthContext } from 'context/AuthContext';
import moment from 'moment-timezone'; // Ensure this line is present

const timezone = 'Asia/Karachi'; // Set your desired timezone

function MarkAttendance() {
  const { user } = useContext(AuthContext);

  const [attendanceStatus, setAttendanceStatus] = useState({
    checkInTimes: [],
    checkOutTimes: [],
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Helper function to get the user's current location (geolocation)
  const getCurrentLocation = () =>
    new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });

  // Fetch attendance logs for today
  const fetchTodayAttendance = async () => {
    if (!user?.email) {
      setMessage('User email not found. Please log in.');
      return;
    }

    try {
      const response = await apiService.get(`/api/v1/users/attendanceLogs`, {
        params: { filter: 'day' },
      });

      if (response.data.success) {
        const attendanceLogs = response.data.data;

        // Check if there are any logs for today
        const todayDate = moment().tz(timezone).format('YYYY-MM-DD');
        const todayLog = attendanceLogs.find((log) =>
          log.attendance.some((entry) => entry.date === todayDate)
        );

        if (todayLog) {
          const todayEntries = todayLog.attendance.find((entry) => entry.date === todayDate).logs;

          const checkInEntries = todayEntries.filter((entry) => entry.status === 'checkIn');
          const checkOutEntries = todayEntries.filter((entry) => entry.status === 'checkOut');

          setAttendanceStatus({
            checkInTimes: checkInEntries.map((entry) => entry.time),
            checkOutTimes: checkOutEntries.map((entry) => entry.time),
          });
        }
      }
    } catch (error) {
      console.error("Error fetching today's attendance logs:", error);
      setMessage("Failed to fetch today's attendance logs.");
    }
  };

  const handleAttendance = async (status) => {
    if (!user?.email) {
      setMessage('User email not found. Please log in.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const currentLocation = await getCurrentLocation();

      // Make API call to mark attendance
      const response = await apiService.post('/api/v1/users/markAttendance', {
        email: user.email,
        status: status,
        currentLocation,
      });

      // Update the attendance status based on response
      if (response.data.success) {
        const { status: attendanceStatus, time } = response.data.attendanceRecord;

        if (status === 'checkIn') {
          setAttendanceStatus((prev) => ({
            ...prev,
            checkInTimes: [...prev.checkInTimes, time],
          }));
        } else if (status === 'checkOut') {
          setAttendanceStatus((prev) => ({
            ...prev,
            checkOutTimes: [...prev.checkOutTimes, time],
          }));
        }

        setMessage(response.data.message);
        // After marking attendance, fetch today's logs to update the state
        await fetchTodayAttendance();
      } else {
        setMessage(response.data.message || 'Failed to mark attendance.');
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred while marking attendance.');
      }
    }

    setLoading(false);
  };

  // Fetch attendance logs for today on component mount
  useEffect(() => {
    fetchTodayAttendance();
  }, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Mark Your Attendance
                </MDTypography>
              </MDBox>

              <MDBox pt={3} px={3}>
                {message && (
                  <MDTypography variant="h6" color="error" mb={2}>
                    {message}
                  </MDTypography>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleAttendance('checkIn')}
                      disabled={loading}
                      fullWidth
                    >
                      {loading ? 'Checking In...' : 'Check In'}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => handleAttendance('checkOut')}
                      disabled={
                        attendanceStatus.checkInTimes.length === 0 ||
                        loading ||
                        !attendanceStatus.checkInTimes[attendanceStatus.checkInTimes.length - 1]
                      }
                      fullWidth
                    >
                      {loading ? 'Checking Out...' : 'Check Out'}
                    </Button>
                  </Grid>
                </Grid>

                <MDBox mt={3}>
                  <MDTypography variant="body1">
                    <strong>Check-In Times:</strong>
                    {attendanceStatus.checkInTimes.length > 0
                      ? attendanceStatus.checkInTimes.join(', ')
                      : 'No Check-ins Yet'}
                  </MDTypography>
                  <MDTypography variant="body1" mt={1}>
                    <strong>Check-Out Times:</strong>
                    {attendanceStatus.checkOutTimes.length > 0
                      ? attendanceStatus.checkOutTimes.join(', ')
                      : 'No Check-outs Yet'}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MarkAttendance;
