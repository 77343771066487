import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel } from '@mui/material';

function AttendanceLogs() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [noRecords, setNoRecords] = useState(false);
  const [filter, setFilter] = useState('day');

  useEffect(() => {
    const fetchAttendanceLogs = async () => {
      try {
        const response = await apiService.get(`/api/v1/attendance/logs?filter=${filter}`);
        if (response.status === 200) {
          const logs = response.data.data;

          if (logs.length === 0) {
            setNoRecords(true);
            setRows([]); // Clear rows when no records
            return;
          }

          // Set the columns for the attendance logs table
          setColumns([
            { Header: 'Name', accessor: 'name', width: '30%', align: 'left' },
            { Header: 'Status', accessor: 'status', width: '30%', align: 'center' },
            { Header: 'Time', accessor: 'time', width: '40%', align: 'center' },
          ]);

          // Prepare rows for the table
          const formattedRows = logs.map((log) => ({
            name: log.name,
            status: log.status,
            time: log.time,
          }));

          setRows(formattedRows);
          setNoRecords(false); // Reset noRecords if there are logs
        }
      } catch (error) {
        toast.error('Failed to fetch attendance logs');
      }
    };

    fetchAttendanceLogs();
  }, [filter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Attendance Logs
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Tenure</InputLabel>
                  <Select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    label="Select Tenure"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{
                      width: '10%',
                      padding: '10px',
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                      },
                    }}
                  >
                    <MenuItem value="day">Last 1 Day</MenuItem>
                    <MenuItem value="week">Last 7 Days</MenuItem>
                    <MenuItem value="month">Last 30 Days</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
              <MDBox pt={3}>
                {noRecords ? (
                  <MDTypography variant="h6" color="text" align="center" my={2}>
                    No attendance logs found.
                  </MDTypography>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AttendanceLogs;
