import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddBox';
import Switch from '@mui/material/Switch';
import { AuthContext } from '../../context/AuthContext';
import { MenuItem, Select, Checkbox, ListItemText, FormControl } from '@mui/material';
import { Approval, DeleteOutline, Edit } from '@mui/icons-material';

function Users() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const { permission } = useContext(AuthContext);
  const { Admin = false } = permission;

  // Fetch Roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesResponse = await apiService.get('/api/v1/roles/list');
        if (rolesResponse.status === 200) {
          setRoles(rolesResponse.data.roles);
        }
      } catch (error) {
        toast.error('Failed to fetch roles');
      }
    };
    fetchRoles();
  }, []);

  // Fetch Users
  useEffect(() => {
    fetchUsersData();
  }, [roles, isApproved]);

  const fetchUsersData = async () => {
    try {
      const usersResponse = await apiService.get('/api/v1/users/list');
      if (usersResponse.status === 200) {
        const users = usersResponse.data.users;
        setColumns([
          { Header: 'Full Name', accessor: 'fullName', align: 'left' },
          { Header: 'Email', accessor: 'email', align: 'left' },
          { Header: 'Person No.', accessor: 'personNumber', align: 'left' },
          // { Header: "Business Unit", accessor: "businessUnit", align: "left" },
          { Header: 'Department', accessor: 'department', align: 'left' },
          { Header: 'Roles', accessor: 'roles', align: 'left' },
          { Header: 'Approved', accessor: 'isApproved', align: 'center' },
          { Header: 'Action', accessor: 'action', align: 'center' },
        ]);

        const formattedRows = users.map((user) => ({
          id: user._id,
          fullName: (
            <Link to={`/users/view/${user._id}`}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {user.fullName || 'N/A'}
              </MDTypography>
            </Link>
          ),
          email: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.email}
            </MDTypography>
          ),
          personNumber: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.personNumber}
            </MDTypography>
          ),
          // businessUnit: (
          //   <MDTypography variant="caption" color="text" fontWeight="medium">
          //     {user.businessUnit || "N/A"}
          //   </MDTypography>
          // ),
          department: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.department?.name || 'N/A'}
            </MDTypography>
          ),
          roles: (
            <FormControl variant="outlined" size="small">
              <Select
                multiple
                value={user.roles.map((role) => role._id)}
                onChange={(e) => handleRoleChange(user._id, e.target.value)}
                renderValue={(selected) =>
                  roles
                    .filter((role) => selected.includes(role._id))
                    .map((role) => role.name)
                    .join(', ')
                }
              >
                {roles.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    <Checkbox checked={user.roles.some((r) => r._id === role._id)} />
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
          isApproved:
            user.declinedStatus != 'notMarked' ? (
              <Switch
                checked={user.isApproved}
                onChange={() => handleToggleApproval(user._id, !user.isApproved)}
                color="primary"
              />
            ) : (
              <MDTypography variant="caption" color="success" fontWeight="medium">
                Pending
              </MDTypography>
            ),
          action: (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <Link to={`/users/edit/${user._id}`}>
                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDelete(user._id)}
              />
            </MDBox>
          ),
        }));

        setRows(formattedRows);
      }
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };

  const handleRoleChange = async (userId, newRoles) => {
    try {
      await apiService.put(`/api/v1/users/update/${userId}`, { roles: newRoles });
      toast.success('User roles updated successfully');
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === userId ? { ...row, roles: newRoles } : row))
      );
    } catch (error) {
      toast.error('Failed to update user roles');
    }
  };

  const handleToggleApproval = async (userId, newStatus) => {
    try {
      await apiService.put(`/api/v1/users/update/${userId}`, { isApproved: newStatus });
      const message = newStatus ? 'User Approved.' : 'User Not Approved.';
      toast.success(message);
      setIsApproved(newStatus);
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === userId ? { ...row, isApproved: newStatus } : row))
      );
    } catch (error) {
      toast.error('Failed to update user approval status');
    }
  };

  const handleDelete = async (userId) => {
    try {
      await apiService.delete(`/api/v1/users/delete/${userId}`);
      toast.success('User deleted successfully');
      setRows((prevRows) => prevRows.filter((row) => row.id !== userId));
    } catch (error) {
      toast.error('Failed to delete user');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
                <Link to={'/users/create'}>
                  {!Admin && (
                    <IconButton color="white">
                      <AddIcon />
                    </IconButton>
                  )}
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
