import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import MDButton from 'components/MDButton';
import { styled } from '@mui/material/styles';
import MDTypography from 'components/MDTypography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minWidth: '150px', // Ensures consistent width
    width: '150px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: '150px', // Ensures consistent width
    width: '150px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // Hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function InformationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  console.log('sds', props);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Sample data for table rows
  const rows = [
    { id: 1, name: 'Location 1', address: '123 Main St' },
    { id: 2, name: 'Location 2', address: '456 Elm St' },
    { id: 3, name: 'Location 3', address: '789 Oak St' },
    // Add more rows as needed
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <MDButton onClick={handleClickOpen} color="secondary" variant="gradient" size="small">
        View
      </MDButton>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Information List</DialogTitle>
        {/* <DialogContent> */}
        {props?.emailLog.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    display: 'contents',
                    width: '100%',
                  }}
                >
                  <StyledTableRow>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {props?.emailLog
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell align="left">{row.email}</StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(row.sentAt).toLocaleString()}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={rows.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </>
        ) : (
          <MDTypography variant="body1" align="center">
            No information available
          </MDTypography>
        )}
        {/* </DialogContent> */}
        <DialogActions>
          <MDButton color="primary" variant="contained" size="medium" onClick={handleClose}>
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
