import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import PropTypes from 'prop-types';
import apiService from 'services/ApiService';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const { departmentId } = props;
  console.log('props', props)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleSendEmail = async () => {
    if (emailError) {
      return;
    }
    try {
      setLoading(true);
      let dataToSend = {
        email,
        departmentId,
      };
      // Send email to the user
      const response = await apiService.post('/api/v1/departments/fetchLoction', dataToSend);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        setLoading(false);
        setEmail('');
        handleClose();
        props?.fetchDepartmentsData();
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
      toast.error('Failed to send email');
    }
  };

  return (
    <React.Fragment>
      {props?.status === 'pending' ? (
        <MDButton onClick={handleClickOpen} color="info" variant="outlined" size="small">
          Add Location
        </MDButton>
      ) : (
        <MDButton onClick={handleClickOpen} color="info" variant="outlined" size="small">
          Update Location
        </MDButton>
      )}

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   component: 'form',
        //   onSubmit: (event) => {
        //     event.preventDefault();
        //     const formData = new FormData(event.currentTarget);
        //     const formJson = Object.fromEntries(formData.entries());
        //     const email = formJson.email;
        //     console.log(email);
        //     handleClose();
        //   },
        // }}
      >
        <DialogTitle>Send Location Identification Link</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Send Location Identification Link</DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
          />
        </DialogContent>
        <DialogActions>
          <MDButton color="primary" variant="contained" size="medium" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton
            disabled={!email || loading}
            onClick={handleSendEmail}
            color="info"
            variant="contained"
            size="medium"
            type="submit"
          >
            {loading ? <CircularProgress color="white" size={'25px'} /> : 'Send'}
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

FormDialog.propTypes = {
  departmentId: PropTypes.string.isRequired,
  status: PropTypes.string,
};
