

import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import bgImage from 'assets/images/background.jpg';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import apiService from 'services/ApiService';
import { useNavigate, useSearchParams } from 'react-router-dom';

function LocationFetcher() {
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [searchParams] = useSearchParams();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const email = searchParams.get('email');
  const departmentId = searchParams.get('departmentId');
  const expiresAt = searchParams.get('expiresAt');
  const signature = searchParams.get('signature');
  const [isLinkValid, setIsLinkValid] = useState(false);
const navigate = useNavigate();

  // Function to validate the link using the backend
  const validateLink = async () => {
    try {
      let dataToSend = {
        email,
        departmentId,
        expiresAt,
        signature,
      };
      const response = await apiService.post('/api/v1/departments/validateLink', dataToSend);


      if (response.data.success) {
        setIsLinkValid(true);
      } else {
        setError(response.data.message || 'Invalid or expired link.');
        setIsLinkValid(false);
      }
    } catch (err) {
      setError('Error validating link. Please try again.');
      console.error('Validation error:', err);
    }
  };

  useEffect(() => {
    // Validate the link on component mount
    validateLink();
  }, [email, departmentId, expiresAt, signature]);

  const handleGetCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
          setError('');

          try {
            let dataToSend = {
              email,
              departmentId,
              latitude,
              longitude,
            };
            // Send email to the user
            const response = await apiService.post('/api/v1/departments/fetchLoction', dataToSend);
            setMessage(response.data.message);
            toast.success(response?.data?.message);
            navigate("/dashboard");
          } catch (err) {
            // setError('Failed to send email. Please try again later.');
            console.error('Error:', err);
          }
        },
        (err) => {
          setError('Error getting location. Please try again.');
          console.error(err);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };
  return (
    <BasicLayout image={bgImage}>
      {isLinkValid ? (
        <>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Location Fetcher
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Click the button below to fetch your current location.
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleGetCurrentLocation}
                  style={{ marginTop: '16px' }}
                >
                  Fetch Location
                </MDButton>
                {location.lat && location.lng && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1">Latitude: {location.lat}</MDTypography>
                    <MDTypography variant="body1">Longitude: {location.lng}</MDTypography>
                  </MDBox>
                )}
                {error && (
                  <MDTypography variant="caption" color="error" mt={2}>
                    {error}
                  </MDTypography>
                )}
                {message && (
                  <MDTypography variant="caption" color="success" mt={2}>
                    {message}
                  </MDTypography>
                )}
              </MDBox>

              {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox> */}
            </MDBox>
          </Card>
        </>
      ) : (
        <>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Invalid Link
              </MDTypography>
             
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
               
                {message && (
                  <MDTypography variant="caption" color="success" mt={2}>
                    {message}
                  </MDTypography>
                )}
              </MDBox>

              {/* <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox> */}
            </MDBox>
          </Card>
        </>
      )}
    </BasicLayout>
  );
}

export default LocationFetcher;
