import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import Header from "layouts/profile/components/Header";
import apiService from "../../services/ApiService";
import { AuthContext } from "../../context/AuthContext";
import { Business } from "@mui/icons-material";

function Overview() {
  // const [profiles, setProfiles] = useState([]);
  const { user, permission } = useContext(AuthContext);
  const { Admin = false, HrManager = false, HRBP = false } = permission;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        firstName={user?.fullName}
        profileImage={user?.profileImage}
        role={user?.roles[0].name}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={5} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                info={{
                  fullName: user?.fullName || "N/A",
                  email: user?.email || "N/A",
                  personNumber: user?.personNumber || "N/A",
                  createdAt: new Date(user?.createdAt).toLocaleDateString(),
                }}
                action={{ route: `/users/edit/${user?._id}`, tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
