import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button } from '@mui/material';

function Roles() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        const response = await apiService.get('/api/v1/roles/list');
        if (response.status === 200) {
          const roles = response.data.roles;

          setColumns([
            { Header: 'Role Name', accessor: 'name', width: '50%', align: 'left' },
            { Header: 'Created At', accessor: 'createdAt', align: 'left' },
            { Header: 'Action', accessor: 'action', align: 'center' },
          ]);

          const formattedRows = roles.map((role) => ({
            id: role._id,
            name: (
              <MDTypography display="block" variant="button" fontWeight="medium">
                {role.name}
              </MDTypography>
            ),
            createdAt: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(role.createdAt).toLocaleDateString()}
              </MDTypography>
            ),
            action: (
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <Link to={`/roles/edit/${role._id}`}>
                  <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
                </Link>
                <MDBox mx={1} />
                <DeleteOutline
                  fontSize="small"
                  color="error"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(role._id)}
                />
              </MDBox>
            ),
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error('Failed to fetch roles data');
      }
    };

    fetchRolesData();
  }, []);

  const handleDelete = async (roleId) => {
    try {
      const response = await apiService.delete(`/api/v1/roles/delete/${roleId}`);
      if (response.status === 200) {
        toast.success('Role deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== roleId));
      }
    } catch (error) {
      toast.error('Failed to delete role');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Roles
                </MDTypography>
                <Link to={'/roles/create'}>
                  <Button
                    variant="contained"
                    style={{ color: '#1A73E8', background: 'white' }}
                    startIcon={<AddIcon style={{ color: '#1A73E8' }} />}
                  >
                    Add New Roles
                  </Button>
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Roles;
